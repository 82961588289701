import { render, staticRenderFns } from "./EditProfileText.vue?vue&type=template&id=99f1f102&scoped=true&"
import script from "./EditProfileText.vue?vue&type=script&lang=js&"
export * from "./EditProfileText.vue?vue&type=script&lang=js&"
import style0 from "./EditProfileText.vue?vue&type=style&index=0&id=99f1f102&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "99f1f102",
  null
  
)

export default component.exports